<template>
  <div class="games-module">
    <ModelsList
      :title="'Games'"
      :modelName="modelName"
      :objects="cGames"
      :fields="listFields"
      :uidField="'id'"
      :selectedObject="selectedGame"
      @selectObject="selectGame"
      @addObject="initCreateGame" />
    <ModelsEdit
      class="content-block"
      size="large"
      :open="modalEditOpen"
      :modelName="modelName"
      :fields="fields"
      :selectedModel="selectedGame"
      :originalModel="listGame"
      @cancelEdit="closeModalEdit"
      @saveEdit="saveGame"
      @resetModel="resetGame"
      @removeModel="removeGame" />
  </div>
</template>

<script>
import { getGamesList, getGame, updateGame, createGame, deleteGame } from '@/firebase'
import ModelsList from '@/components/admin/ModelsList'
import ModelsEdit from '@/components/admin/ModelsEdit'
import { stringToDate, dateToString, validURL, toggleDimmer } from '@/util'

export default {
  name: "GamesModule",
  inject: ["createNotification", "createDialog"],
  components: {
    ModelsList,
    ModelsEdit,
  },
  data() {
    return {
      modelName: 'game',
      keys: [
        'name',
        'description',
        'tags',
        'media',
        'team',
        'downloadLink',
        'playLink',
        'itchLink',
      ],
      listFields: [
        { key: 'name', label: 'Name' },
        { key: 'team', label: 'Team' },
      ],
      fields: [
        { key: 'name', label: 'Name', required: true },
        { key: 'tags', label: 'Tags', required: true, type: 'array' },
        { key: 'description', label: 'Description', required: true, fullWidth: true, type: 'textarea' },
        { key: 'media', label: 'Media', fullWidth: true, required: true, type: 'media-array' },
        { key: 'team', label: 'Team', required: true, type: 'array' },
        { key: 'downloadLink', label: 'Download link' },
        { key: 'playLink', label: 'Play link' },
        { key: 'itchLink', label: 'Itch.io link' },
      ],
      games: [],
      selectedGame: null,
      listGame: null,
      noConfirm: false,
      modalEditOpen: false,
      confirmPopupOpen: false,
    }
  },
  computed: {
    cGames() {
      return this.games
    },
    changes() {
      return this.selectedGame !== null ? this.gameHasChanges(this.selectedGame) : []
    },
    showChangeDialog() {
      // Check if user prefs don't indicate a skip, a game is currently selected and that game in question has changes
      if (!this.noConfirm && this.selectedGame && (this.changes === true || this.changes.length !== 0)) {
        return true
      } else {
        return false
      }
    },
    unsavedChangesMessage() {
      let changesListBuilder = ''
      if (this.changes !== true) {
        for (const change of this.changes) {
          changesListBuilder += `<li>${change}</li>`
        }
      }
      return this.changes === true ?
        `<span>You didn't save this newly created ${this.modelName}. All changes will be lost.</span>` :
        `<span>Your following changes will be lost:</span><br /><ul style="display: inline-block; padding: 0; text-align: left;">${changesListBuilder}</ul>`
    }
  },
  methods: {
    onDataChange(items) {
      let games = []

      items.forEach(item => {
        games.push({
          id: item.id,
          name: item.name,
          description: item.description,
          tags: item.tags,
          media: item.media,
          team: item.team,
          downloadLink: item.downloadLink,
          playLink: item.playLink,
          itchLink: item.itchLink,
        })
      })

      this.games = games
    },
    gameHasChanges(game) {
      const listGame = this.games.find(e => e.id === game.id)
      if (listGame === undefined) {
        // The game does not exist in the list. This is a newly created game, all fields are lost changes.
        return true
      } else {
        let changes = []
        
        // Loop over the object's props and add all changes to the list of changes
        for (const key in game) {
          if (game[key] !== listGame[key]) {
            // Add change to the list, with a custom name based on the changed prop
            let changeName
            for (const field of this.fields) {
              if (field.key === key) {
                changeName = field.label
                break;
              }
            }

            if (changeName === undefined) {
              changeName = key
            }

            changes.push(changeName)
          }
        }

        return changes
      }
    },
    selectGame(id) {
      // Select the new game as a deep clone
      this.selectedGame = { ...this.games.find(e => e.id === id) }
      this.listGame = { ...this.games.find(e => e.id === id) }

      // Open the edit modal
      toggleDimmer(true, () => this.closeModalEdit(false))
      this.modalEditOpen = true
    },
    closeModalEdit(confirmedPopup) {
      // Visually hide the modal
      this.modalEditOpen = false

      if (!confirmedPopup && this.showChangeDialog && !this.confirmPopupOpen) {
        // Display popup. On cancel, re-open modal. On confirm, re-call method with
        // confirmedPopup true to clear the selected game
        this.confirmPopupOpen = true
        this.createDialog({
          type: 'alert-warning',
          title: 'Close edit?',
          message: this.unsavedChangesMessage,
          options: [
            {
              type: 'cancel',
              text: 'Cancel',
              callback: () => {
                this.confirmPopupOpen = false
                this.modalEditOpen = true
              }
            },
            {
              type: 'confirm',
              text: 'Close edit',
              callback: () => {
                this.confirmPopupOpen = false
                this.closeModalEdit(true)
              }
            }
          ],
          controlDimmer: false,
        })
      } else if (!this.confirmPopupOpen) {
        // Close the dimmer and clear the selected game
        toggleDimmer(false)
        this.confirmPopupOpen = false
        this.selectedGame = null
      }
    },
    initCreateGame(confirmedPopup) {
      // Only show dialog if unsaved changes are present
      if (!confirmedPopup && this.showChangeDialog) {
        // Display popup and on confirm, re-call method with confirmedPopup true
        this.createDialog({
          type: 'alert-warning',
          title: `Select other ${this.modelName}?`,
          message: this.unsavedChangesMessage,
          options: [
            {
              type: 'cancel',
              text: 'Cancel',
              callback: () => {}
            },
            {
              type: 'confirm',
              text: `Select ${this.modelName}`,
              callback: () => this.initCreateGame(true)
            }
          ],
          controlDimmer: false,
        })
      } else {
        // Create a new local game in the selectedGame variable
        this.selectedGame = {
          id: 'temp',
          name: '',
          description: '',
          tags: [],
          media: [],
          team: [],
          downloadLink: '',
          playLink: '',
          itchLink: '',
        }

        // Open the edit modal
        toggleDimmer(true, () => this.closeModalEdit(false))
        this.modalEditOpen = true
      }
    },
    resetGame(id) {
      getGame(id).then(dbGame => {
        if (dbGame) {
          this.selectedGame.id = dbGame.id
          this.selectedGame.name = dbGame.name
          this.selectedGame.description = dbGame.description
          this.selectedGame.tags = dbGame.tags
          this.selectedGame.media = dbGame.media
          this.selectedGame.team = dbGame.team
          this.selectedGame.downloadLink = dbGame.downloadLink
          this.selectedGame.playLink = dbGame.playLink
          this.selectedGame.itchLink = dbGame.itchLink
        } else {
          console.error(`${this.modelName} could not be found in DB, can't reset`)
        }
      }).catch(error => {
        console.error(error)
      })
    },
    saveGame(e) {
      e.preventDefault()
      const game = this.selectedGame
      
      // Game data validation
      try {
        // Required fields validation
        const form = document.querySelector('form.models-edit')
        const reqFields = form.querySelectorAll('[required]')
        reqFields.forEach(field => {
          if (field.value == null || field.value == '') {
            field.focus()
            throw 'You forgot to enter a required field'
          }
        })

        // Link validation: Not a valid link
        if (game.downloadLink && !validURL(game.downloadLink)) {
          throw 'The given link is invalid'
        }

        if (game.playLink && !validURL(game.playLink)) {
          throw 'The given link is invalid'
        }

        if (game.itchLink && !validURL(game.itchLink)) {
          throw 'The given link is invalid'
        }

        const gameObject = {
          name: game.name,
          description: game.description,
          tags: game.tags,
          media: game.media,
          team: game.team,
          downloadLink: game.downloadLink,
          playLink: game.playLink,
          itchLink: game.itchLink,
        }
        
        // Attempt to update the DB
        try {
          // If the game is a new game that doesn't exist yet in the DB, create it in the DB and store its ID
          if (game.id === 'temp') {
            createGame(gameObject).then(result => {
              game.id = result

              // Succesfully created the game in the database, store it in the list too
              this.games.push({ ...game })
              this.listGame = { ...game }

              this.createNotification({
                type: 'confirm',
                title: `Success`,
                message: `${this.modelName} created succesfully!`
              })
            }).catch(e => {
              this.createNotification({
                type: 'error',
                title: `Error creating the ${this.modelName}`,
                message: e,
                duration: 10
              })
            })
          } else {
            // Update the game with the given ID with the new data in the DB
            updateGame(game.id, gameObject)

            // Succesfully updated the game in the database, store it in the list too
            let gIndex = this.games.findIndex(e => e.id === game.id)
            this.games[gIndex] = { ...game }
            this.listGame = { ...game }

            this.createNotification({
              type: 'confirm',
              title: `Success`,
              message: `${this.modelName} saved succesfully!`
            })
          }
        } catch (e) {
          this.createNotification({
            type: 'error',
            title: 'Error during saving',
            message: e,
            duration: 10
          })
        }

      } catch (e) {
        this.createNotification({
          type: 'error',
          title: 'Error during validation',
          message: e,
          duration: 10
        })
      }
    },
    removeGame(id, confirmedPopup) {
      // Visually hide the modal
      this.modalEditOpen = false

      if (!confirmedPopup) {
        // Display popup and on confirm, re-call method with confirmedPopup true
        this.createDialog({
          type: 'alert-warning-remove',
          title: `Delete ${this.modelName}?`,
          message: 'This action cannot be undone',
          options: [
            {
              type: 'cancel',
              text: 'Cancel',
              callback: () => this.modalEditOpen = true
            },
            {
              type: 'confirm',
              text: `Delete ${this.modelName}`,
              callback: () => this.removeGame(id, true)
            }
          ],
          controlDimmer: false,
        })
      } else {
        // Remove the game
        getGame(id).then(dbGame => {
          if (dbGame) {
            // If game is in the DB, remove it from the DB first before removing it locally
            deleteGame(id).then(() => {
              this.deleteLocalGame(id)
            })
          } else {
            // If game is not in the DB, remove it only locally (if it's in the list)
            if (this.listGame !== null) this.deleteLocalGame(id)
          }
          this.selectedGame = null
          this.listGame = null
          this.createNotification({
            type: 'confirm',
            title: `Deleted ${this.modelName}`,
            message: `${this.modelName} has been succesfully deleted`
          })
        }).catch(e => {
          this.createNotification({
            type: 'error',
            title: `Error deleting ${this.modelName}`,
            message: e.message
          })
        })

        // Close the dimmer and clear the selected game
        toggleDimmer(false)
        this.selectedGame = null
      }
    },
    deleteLocalGame(id) {
      // Remove the element from the local games list
      const eIndex = this.games.findIndex(e => e.id === id)
      if (eIndex !== -1) {
        this.games.splice(eIndex, 1)
      } else {
        console.error(`This ${this.modelName} was not found in the local ${this.modelName}s list. Please try reloading the page or contact a moderator.`)
      }
    },
  },
  mounted() {
    getGamesList(list => {
      this.onDataChange(list)
    })
  },
}
</script>
