<template>
  <AdminOverview>
    <h1 class="dashboard-title">Manage games</h1>
    <GamesModule />
  </AdminOverview>
</template>

<script>
import AdminOverview from '@/components/admin/AdminOverview'
import GamesModule from '@/components/admin/GamesModule.vue'

export default {
  name: 'Dashboard',
  components: {
    AdminOverview,
    GamesModule,
  },
}
</script>