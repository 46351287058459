<template>
  <form :class="['models-edit', 'modal', { 'open': open }, size]">
    <div class="block-title space-between">
      <h2>Selected {{ modelName }}</h2>
      <div>
        <i class="fas fa-undo-alt title-icon" title="Reset to saved values"
          v-if="selectedModel && selectedModel.id !== 'temp'" @click="resetModel"></i>
        <i class="fas fa-trash title-icon" :title="`Delete ${modelName}`" v-if="selectedModel" @click="removeModel"></i>
      </div>
    </div>

    <div class="models-edit-grid" v-if="selectedModel">
      <div v-for="({ key, label, fullWidth, type, required }) in fields" :class="[
        'field', `${modelName}-${key}`,
        { 'changed': this.originalModel === null || this.selectedModel[key] !== this.originalModel[key] },
        { 'full-width': fullWidth }
      ]">
        <textarea v-if="type == 'textarea'" :name="key" :id="`e${key}`" v-model="selectedModel[key]"></textarea>

        <div v-else-if="type == 'media-array'" class="media">
          <div v-for="(media, index) in selectedModel[key]" :key="index" class="media-item">
            <div class="media-item-image">
              <ModelImage :model="selectedModel" :mediaIndex="index" />
            </div>
            <div class="media-item-actions">
              <i class="fas fa-trash" title="Remove media" @click="selectedModel[key].splice(index, 1)"></i>
            </div>
          </div>
          <div class="media-item add-item drop-area">
            <i class="fas fa-plus"></i>
            <input type="file" accept="image/*" @change="addMedia($event, key)" />
          </div>
        </div>

        <input v-else type="text" :name="key" :id="`e${key}`" v-model="selectedModel[key]" :required="required">
        <label :for="key">{{ label }}</label>
      </div>
    </div>

    <div class="models-edit-buttons" v-if="selectedModel">
      <input class="button cancel" type="cancel" value="Cancel" @click="cancelEdit">
      <input class="button" :value="`${selectedModel.id === 'temp' ? 'Create' : 'Save'} ${modelName}`" @click="saveEdit">
    </div>

    <i class="hint" v-else>Please select a {{ modelName }} to edit</i>
  </form>
</template>

<script>
import { highlight, unhighlight, displayDropArea, hideDropArea } from '@/util'
import ModelImage from '@/components/admin/ModelImage'

export default {
  name: "ModelEdit",
  components: {
    ModelImage,
  },
  props: {
    modelName: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    selectedModel: {
      type: Object,
      required: false,
    },
    originalModel: {
      type: Object,
      required: false,
      default: null,
    },
    open: {
      type: Boolean,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
    },
  },
  emits: ['saveEdit', 'cancelEdit', 'resetModel', 'removeModel'],
  methods: {
    saveEdit(e) {
      this.$emit('saveEdit', e)
    },
    cancelEdit(e) {
      e.preventDefault()
      this.$emit('cancelEdit', false)
    },
    resetModel() {
      this.$emit('resetModel', this.selectedModel.id)
    },
    removeModel() {
      this.$emit('removeModel', this.selectedModel.id)
    },
    pad(num, padlen, padchar) {
      var pad_char = typeof padchar !== 'undefined' ? padchar : '0';
      var pad = new Array(1 + padlen).join(pad_char);
      return (pad + num).slice(-pad.length);
    },
    handleDrop(e) {
      let dt = e.dataTransfer
      let files = dt.files
      let file = files[0]

      console.log(file)
    },
    setUpDropAreas() {
      const body = document.querySelector('body')
      const dropAreas = document.querySelectorAll('.drop-area')
      const pageOverlay = document.querySelector('#page-overlay')
      if (dropAreas.length == 0) return

      // Set up every dropArea
      for (const dropArea of dropAreas) {
        // Remove unnecessary bubbling for drag events
        ['dragenter', 'dragover', 'dragleave'].forEach(eventName => {
          dropArea.addEventListener(eventName, (e) => {
            e.preventDefault()
            e.stopPropagation()
          }, false)
        })

        // Add dropArea bordering when dragging a file over the body
        body.addEventListener('dragenter', () => displayDropArea(dropArea))
        body.addEventListener('drop', () => hideDropArea(dropArea))
        body.addEventListener('dragover', (e) => e.preventDefault()) // Necessary for drop event to fire on all drops
        body.addEventListener('dragleave', (e) => {
          if (e.relatedTarget == null) hideDropArea(dropArea)
        })

        // Add dropArea highlighting when dragging a file over the dropArea itself
        ;['dragenter'].forEach(eventName => dropArea.addEventListener(eventName, () => highlight(dropArea), false))
        ;['dragleave', 'drop'].forEach(eventName => dropArea.addEventListener(eventName, () => unhighlight(dropArea), false))

        // Handle dropped files
        dropArea.addEventListener('drop', this.handleDrop, false)
      }
    },
    tearDownDropAreas() {
      const body = document.querySelector('body')
      const dropAreas = document.querySelectorAll('.drop-area')
      if (dropAreas.length == 0) return

      for (const dropArea of dropAreas) {
        // Remove dropArea bordering when dragging a file over the body
        body.removeEventListener('dragenter', () => displayDropArea(dropArea), false)
        body.removeEventListener('drop', () => hideDropArea(dropArea), false)
        body.removeEventListener('dragleave', (e) => {
          if (e.relatedTarget == null) hideDropArea(dropArea)
        }, false)

        // Remove dropArea highlighting when dragging a file over the dropArea itself
        ;['dragenter'].forEach(eventName => dropArea.removeEventListener(eventName, () => highlight(dropArea), false))
        ;['dragleave', 'drop'].forEach(eventName => dropArea.removeEventListener(eventName, () => unhighlight(dropArea), false))

        // Remove dropArea event handlers
        dropArea.removeEventListener('drop', this.handleDrop, false)
      }
    },
  },
  watch: {
    open: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.setUpDropAreas()
          })
        } else {
          this.$nextTick(() => {
            this.tearDownDropAreas()
          })
        }
      }
    }
  },
}
</script>

<style lang="scss">
.models-edit {

  &.modal {
    position: absolute;
    display: none;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    z-index: 15;

    &.large {
      width: 1000px;
      max-width: 90vw;
    }

    &.open {
      display: block;
    }
  }
  
  &-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem 2rem;
  }

  .field {
    display: flex;
    flex-direction: column-reverse;

    &.full-width {
      grid-column: 1 / span 2;
    }

    &.changed label {
      font-weight: 500;
      color: #ccc;
    }

    label, input {
      display: block;
    }

    label {
      font-size: 14px;
      margin-bottom: 8px;
      color: var(--color-text-light);
    }

    input:required + label::after,
    textarea:required + label::after {
      content: '*';
      vertical-align: text-top;
      color: var(--color-red);
      margin-left: 2px;
    }
  }

  input[type=text], textarea {
    width: 100%;
    outline: none;
    color: #fff;
    font: 17px "Rubik", sans-serif;
    transition: background-color 200ms ease, border-color 200ms ease;
    background-color: transparent;
    border: 2px solid #817F98;
    padding: .5rem 1rem;
    border-radius: 1000px;
    resize: none;

    &::placeholder {
      color: #817F98;
    }

    &:focus, &:hover {
      border-color: #fff;
    }

    &:disabled {
      background-color: #817F98;
      color: #fff;

      &::placeholder {
        color: #817F98;
      }

      &:focus, &:hover {
        border-color: #817F98;
      }
    }
  }

  textarea {
    border-radius: 16px;
    min-height: 130px;
  }

  .media {
    display: flex;
    gap: 12px;

    .media-item {
      position: relative;
      height: 100px;
      aspect-ratio: 16 / 9;
      border-radius: 16px;
      background-color: #817F98;
      border: 2px solid #817F98;
      transition: opacity 200ms ease;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px;
      }

      .media-item-actions {
        display: none;
        position: absolute;

        &:hover {
          display: block;
        }
      }

      &.add-item {
        border: 2px solid #817F98;
        cursor: pointer;

        * {
          pointer-events: none;
        }
      
        &.droppable {
          border-style: dashed;
          border-color: rgba(255, 255, 255, 0.6);
        }
        &.highlight {
          border-style: dashed;
          border-color: var(--color-purple-lightest);
        }
        
        input[type=file] {
          height: 0;
          width: 0;
          overflow: hidden;
        }

        i {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
          font-size: 2rem;
          font-weight: 500;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .models-edit-buttons {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  .button {
    width: 100%;
    border-radius: 1000px;
    text-align: center;
    margin-top: 2rem;

    &.cancel {
      background-color: transparent;
      color: var(--color-text-light);
      border-color: var(--color-text-light);

      &:hover, &:focus {
        color: #aaa;
        border-color: #aaa;
      }
    }
  }

  .hint {
    color: var(--color-text-light);
  }
}
</style>